require('slick-carousel');
let Slider = {

    Simple : () => {
        
        $('.slider-items').slick({
            autoplay:true,
            arrows: true,
            prevArrow:'.prev',
            nextArrow:'.next',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            centerMode: true,
            centerPadding: '1px',
            cssEase: 'linear',
            responsive: [
            {
              breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        
            ]
        });
    },

    WithThumbnail : () => {

        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: true,
            centerMode: true,
            focusOnSelect: true
        });
    }
}
module.exports = Slider;
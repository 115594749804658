let Common = {

    Init : () => {
        Common.Scroll();
    },
    
    Scroll : () => {

        let $header = $('.header');

        window.addEventListener("scroll", (event) => {
            
            let posY = window.pageYOffset;

            if (posY >= 400) {
                $header.addClass('header-small');
                $('.header-bg-white').fadeOut(100);
            } else {
                $header.removeClass('header-small');
                $('.header-bg-white').fadeIn(300);
            }
        });
    }
}
module.exports = Common;
let AbrisBlock = {

    Init : () => {
        if ($('.type-abris').length && $('.type-content').length) {

            $(document).off('click', '.type-abris').on('click', '.type-abris', function(){
                var nav = $(this).attr('data-nav');
                $('.type-abris').removeClass("active");
                $(this).addClass("active");
        
                $('.type-content').hide();
                $('.type-content[data-nav="'+nav+'"]').show();
            });
            $('.type-abris').eq(1).trigger('click');
          }
    }
}
module.exports = AbrisBlock;
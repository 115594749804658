import '../scss/app.scss';

'use-strict';

let jQuery  = require('jquery');

window.$    = jQuery.noConflict();
window.$d   = $(document);
let $body       = $('body');


$d.ready(function() {

    require('./modules/Common').Init();
    require('./modules/Header').Init();
    require('./modules/Widgetinfo').Init();
    require('./modules/AbrisBlock').Init();

    if ($body.hasClass('home') || $body.hasClass('single-abri')) {
        require('./modules/Slider').Simple();
    }

    if ($body.hasClass('page-template-default') || $body.hasClass('page-template-template-cms')) {
        require('./modules/Slider').Simple();
    }

    if (
        $body.hasClass('page-template-template-avantagesoptions') ||
        $body.hasClass('page-template-template-nos-abris')
    ) {
        require('./modules/Abris').Init();
    }


    var buttons = document.querySelectorAll('[data-bs-toggle="collapse"]');
    buttons.forEach(function(button) {
        button.addEventListener('click', function() {
            var targetId = button.getAttribute('data-bs-target');
            var content = document.querySelector(targetId);

            // Fermez tous les éléments sauf celui sur lequel l'utilisateur a cliqué
            buttons.forEach(function(otherButton) {
                var otherTargetId = otherButton.getAttribute('data-bs-target');
                var otherContent = document.querySelector(otherTargetId);
                if (otherContent !== content && otherContent.classList.contains('show')) {
                    otherButton.classList.add('collapsed');
                    otherContent.classList.remove('show');
                }
            });

            if (content.classList.contains('show')) {
                content.classList.remove('show');
                button.classList.add('collapsed');
            } else {
                content.classList.add('show');
                button.classList.remove('collapsed');
            }
        });
    });

})

let Header = {

    Init : () => {
        console.log('*** HEADER ***')

        if ($('#check').is(':visible')) {

            if ($('#check').prop('checked')) {
                $('.mobile-menu-items').show();
            } else {
                $('.mobile-menu-items').hide();
            }
            return;
        }

        $d.off('mouseenter', '.menu-item-level-0').on('mouseenter', '.menu-item-level-0', function(){
            $('.level-0').hide();
            if ($(this).children('.level-0').children('ul').children('li').length) {
                $(this).children('.level-0').show();
                $('.level-1').first().show();
            }
        });

        $d.off('mouseleave', '.level-0').on('mouseleave', '.level-0', function(){
            $('.level-0').hide();
            return;
        });

        $d.off('mouseenter', '.menu-item-level-1').on('mouseenter', '.menu-item-level-1', function(){
            
            $('.level-1').hide();

            if ($(this).children('.level-1').children('ul').children('li').length) {
                $(this).children('.level-1').show();
            }

        });

        $d.off('change', '#check').on('change', '#check', function(){
            if ($(this).prop('checked')) {
                $('.mobile-menu-items').show();
            } else {
                $('.mobile-menu-items').hide();
            }
            return;
        });

        $d.off('click', '.mobile-menu-item-level-0 > a').on('click', '.mobile-menu-item-level-0 > a', function(e){

            e.preventDefault();

            let href = $(this).attr('href');

            $('.mobile-level-0').hide();
            $('.mobile-level-1').hide();
            if ($(this).parent().children('.mobile-level-0').children('.mobile-menu-items').children('ul').children('li').length) {
                $(this).parent().children('.mobile-level-0').show();
            } else {
                window.location.href = href;
            }
        });

        $d.off('click', '.mobile-menu-item-level-1 > a').on('click', '.mobile-menu-item-level-1 > a', function(e){

            e.preventDefault();

            let href = $(this).attr('href');

            $('.mobile-level-1').hide();
            if ($(this).parent().children('.mobile-level-1').children('.mobile-menu-items').children('ul').children('li').length) {
                $(this).parent().children('.mobile-level-1').show();
            } else {
                window.location.href = href;
            }
        });
    }
}
module.exports = Header;
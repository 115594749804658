let Widgetinfo = {

    Init : () => {
        if ($('.widget-info').length && $('.icon-link').length && $('.text-link').length) {

            $d.off('mouseenter', '.icon-link').on('mouseenter', '.icon-link', function(){
                $('.text-link').removeClass('text-link-visible');
                $(this).parent().children('.text-link').delay(100).addClass('text-link-visible');
            });
            
            $d.off('mouseleave', '.widget-info').on('mouseleave', '.widget-info', function(){
                $('.text-link').removeClass('text-link-visible');
                return;
            });
        }
    }
}
module.exports = Widgetinfo;
let Abris = {

    Init : () => {

        $d.off('mouseenter', '.option-info').on('mouseenter', '.option-info', function(){
            $('.desc-option').hide();
            $(this).parent().children('.desc-option').show();
            return;
        });

        $d.off('mouseleave', '.desc-option').on('mouseleave', '.desc-option', function(){
            $('.desc-option').hide();
            return;
        });
    }
}
module.exports = Abris;